@import "common/_vars";
@import "common/_mixins";

@media print {
    .skip-main,
    .header,
    .banner,
    .hero-bg,
    .footer,
    .signoff,
    .slider,
    .breadcrumbs,
    .product-details__col--thumbs,
    .share,
    #similar,
    #recently-viewed,
    .hide-print,
    .purchase-options__col--unit,
    .purchase-options__col--input,
    .purchase-actions,
    .filter__col--filter,
    .filter__col--sort,
    .pagination {
        display: none;
    }

    h1,h2,h3,h4,h5,h6 {
        page-break-after: avoid;
    }

    .data-list {
        
        dt {
            font-weight: bold;
            display: inline-block;
        }
        dd {
            display: inline-block;
            margin: 0;

            &:after {
                content: ' / ';
            }
        }
    }
    body {
        font-size: 10px;
    }

    img {
        max-width: 100%;
    }

    .product-details__img {
        img {
            max-width: 200px;
        }
    }

    img,
    ul,
    dl,
    button {page-break-inside: avoid;}

    .accordion {
        height: auto !important;
        > * {
            height: auto !important;
            transform: translate3d(0,0,0);
        }
    }

    .gl {
        width: calc(100% + 20px);
        display:block;
        margin-left: -10px;

        &:after {
            content: '';
            display: table;
            clear: both;
        }
    }

    .gl__col {
        margin-bottom: 20px;
        padding: 0 10px;
        float: left;
        max-width: 25%;
        width: 25%;
    }

    @page {
        margin: 1cm;
    }
}